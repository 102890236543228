import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { areEqual } from 'Utils/equalityChecks';
import { TabContent } from 'Components/Tabs';
import { PurpleButton } from 'Components/Button';
import { ErrorMessage, RocketScopeDataGrid } from 'Components/RocketScope';
import { width } from 'Utils/screen';
import { firstCompanyIdSelector } from 'Containers/Projects/selectors';
import { SpinnerBlock } from 'Components/SpinnerBlock';
import { NewScopeCategoryModal } from 'Components/RocketScope/NewScopeCategoryModal';
import { ScopeData, ScopeSheet } from 'Containers/RocketScope/types';
import writeXlsxFile from 'write-excel-file';
import { Icon } from 'Components/Icons';
import { filterChangeData } from 'Containers/RocketScope/helpers';
import {
  changeScope,
  deleteScope,
  fetchScopeData,
  setIsEditing,
  setHasChanges,
  setLoading,
  setSelectedRow,
  setChanges,
  setRemoved,
  setNewCategory,
  setIsNewCategoryOpen,
  setNewCategoryFormErrors,
  setSaveError,
  setCurrentSheet,
  setScopeSheets,
  replaceSingleSheet,
} from '../../actions';
import {
  scopeSheetsSelector,
  currentSheetSelector,
  hasChangesSelector,
  isEditingSelector,
  loadingSelector,
  selectedRowSelector,
  changesSelector,
  removedSelector,
  newCategorySelector,
  isNewCategoryOpenSelector,
  newCategoryFormErrorsSelector,
  saveErrorSelector,
} from '../../selectors';
import classes from './rocketScopeViewTab.module.css';

function RocketScopeViewTabContainer() {
  const dispatch = useDispatch();
  const firstCompanyId = useSelector(firstCompanyIdSelector, areEqual);

  const scopeSheets: undefined | ScopeSheet[] = useSelector(scopeSheetsSelector);
  const currentSheet: number = useSelector(currentSheetSelector);
  const hasChanges: boolean = useSelector(hasChangesSelector);
  const isEditing: boolean = useSelector(isEditingSelector);
  const loading: boolean = useSelector(loadingSelector);
  const selectedRow: number | undefined = useSelector(selectedRowSelector);
  const changes: { [index: number]: number[] } = useSelector(changesSelector);
  const removed: { [index: number]: number[] } = useSelector(removedSelector);
  const newCategory: string = useSelector(newCategorySelector);
  const isNewCategoryOpen: boolean = useSelector(isNewCategoryOpenSelector);
  const newCategoryFormErrors: { category: string[] } = useSelector(newCategoryFormErrorsSelector);
  const saveError: string = useSelector(saveErrorSelector);

  const newSheetClickHandler = useRef(null);
  const originalScopeSheets = useRef<ScopeSheet[] | null>(null);

  function getColumns() {
    return [
      { column: 'Category', type: String, value: (scope) => scope.category, width: 20 },
      { column: 'Xactimate Code (Part 1)', type: String, value: (scope) => scope.codePart1, width: 30 },
      { column: 'Xactimate Code (Part 2)', type: String, value: (scope) => scope.codePart2, width: 30 },
      { column: 'Desc', type: String, value: (scope) => scope.description, width: 60 },
      { column: 'Unit', type: String, value: (scope) => scope.unit, width: 6 },
      { column: 'Rate $', type: Number, value: (scope) => parseFloat(scope.rate), width: 10, format: '#,##0.00' },
      { column: 'Notes', type: String, value: (scope) => scope.notes, width: 750 },
    ];
  }

  useEffect(() => {
    if (scopeSheets === undefined) {
      dispatch(fetchScopeData(firstCompanyId));
    } else if (originalScopeSheets.current === null) {
      originalScopeSheets.current = scopeSheets;
    }
  }, [dispatch, scopeSheets]);

  useEffect(() => {
    newSheetClickHandler.current = () => {
      const newScopeSheets: ScopeSheet[] = [...scopeSheets];
      newScopeSheets.push({
        id: 0,
        name: newCategory,
        data: [],
      });
      dispatch(setCurrentSheet(newScopeSheets.length - 1));
      dispatch(setScopeSheets(newScopeSheets));

      dispatch(setSelectedRow(null));
      dispatch(setIsNewCategoryOpen(false));
    };
  }, [dispatch, newCategory, scopeSheets]);

  const onRowsChange = useCallback(
    (newRows: ScopeData[]) => {
      let hasChange = false;

      const newChanges = { ...changes };
      for (let i = 0; i < scopeSheets[currentSheet].data.length; i += 1) {
        if (scopeSheets[currentSheet].data[i] !== newRows[i]) {
          if (!newChanges[currentSheet]) {
            newChanges[currentSheet] = [];
          }
          if (!newChanges[currentSheet].includes(newRows[i].id)) {
            newChanges[currentSheet].push(newRows[i].id);
          }
          hasChange = true;
          break;
        }
      }
      if (hasChange) {
        dispatch(setChanges(newChanges));
        dispatch(setHasChanges(Object.keys(newChanges).length > 0));

        const newScopeData = [...scopeSheets];
        newScopeData[currentSheet].data = newRows;
        dispatch(setScopeSheets(newScopeData));
      }
    },
    [dispatch, scopeSheets, currentSheet]
  );

  const editScope = useCallback(() => {
    dispatch(setIsEditing(!isEditing));
  }, [dispatch, isEditing]);

  const saveChanges = useCallback(() => {
    if (hasChanges) {
      const promises: Promise<void>[] = [];

      const filteredChanges = filterChangeData(
        changes,
        scopeSheets.map((sheet) => sheet.data)
      );

      const numberOfChanges = Object.keys(changes).reduce((accum, val) => accum + val.length, 0);
      const numberOfFiltered = Object.keys(filteredChanges).reduce((accum, val) => accum + val.length, 0);
      if (numberOfFiltered !== numberOfChanges) {
        dispatch(
          setSaveError(
            'Cannot save, some rows are invalid (missing Category, Description or Unit) -- fix and save again'
          )
        );
        dispatch(setSelectedRow(null));
        return;
      }

      let changeData = Object.keys(filteredChanges).map((sheetIndex) => ({
        sheetId: scopeSheets[sheetIndex].id,
        name: scopeSheets[sheetIndex].name,
        changes: filteredChanges[sheetIndex],
      }));

      if (changeData.length > 0 || Object.keys(removed).length > 0) {
        const replaceData: { sheetId: number; scopeData: ScopeData[]; sheetName: string }[] = [];

        // Check if new rows are added in the middle
        Object.keys(filteredChanges).forEach((sheetIndex) => {
          const originalData = originalScopeSheets.current[sheetIndex].data;
          const currentData = scopeSheets[sheetIndex].data;

          let isInsertedInMiddle = false;
          for (let i = 0; i < currentData.length; i += 1) {
            if (currentData[i].id === 0 && i < originalData.length) {
              isInsertedInMiddle = true;
              break;
            }
          }

          if (isInsertedInMiddle) {
            const scopeSheet: ScopeSheet = scopeSheets[sheetIndex];
            replaceData.push({
              sheetId: scopeSheet.id,
              scopeData: currentData,
              sheetName: scopeSheet.name,
            });
            changeData = changeData.filter((data) => data.sheetId !== scopeSheet.id);
            scopeSheet.data.forEach((row) => {
              if (removed[row.id]) {
                delete removed[row.id];
              }
            });
          }
        });

        originalScopeSheets.current = null; // Set originalScopeSheets to null so that when the new scope sheet gets loaded it gets set.

        if (replaceData.length > 0) {
          replaceData.forEach((data) => {
            promises.push(
              new Promise((resolve, reject) => {
                dispatch(
                  replaceSingleSheet(firstCompanyId, data.sheetId, data.scopeData, data.sheetName, resolve, reject)
                );
              })
            );
          });
        }
        if (changeData.length > 0) {
          promises.push(
            new Promise((resolve, reject) => {
              dispatch(changeScope(firstCompanyId, changeData, resolve, reject));
            })
          );
        }
        if (Object.keys(removed).length > 0) {
          const removedData = Object.keys(removed).map((sheetIndex) => ({
            sheetId: scopeSheets[sheetIndex].id,
            removed: removed[sheetIndex],
          }));
          promises.push(
            new Promise((resolve, reject) => {
              dispatch(deleteScope(firstCompanyId, removedData, resolve, reject));
            })
          );
        }

        Promise.all(promises)
          .then(() => {
            dispatch(setHasChanges(false));
            dispatch(setIsEditing(false));
            dispatch(setLoading(false));
            dispatch(setChanges({}));
            dispatch(setRemoved({}));
          })
          .catch(() => {
            dispatch(setSaveError('Server Error saving scopes. Try again later'));
            dispatch(setLoading(false));
          });
      } else {
        dispatch(setSaveError('No changes to save - fix missing or data or duplicate rows'));
      }
    }
  }, [dispatch, hasChanges, scopeSheets, changes, firstCompanyId, removed]);

  const onSelectionChange = useCallback(
    (newSelectedRow: number | null) => {
      dispatch(setSelectedRow(newSelectedRow));
    },
    [dispatch]
  );

  const startAddSheetClick = useCallback(() => {
    dispatch(setNewCategory(''));
    dispatch(setNewCategoryFormErrors({ category: [] }));
    dispatch(setIsNewCategoryOpen(true));
  }, [dispatch]);

  const addRow = useCallback(() => {
    const newScopeData = [...scopeSheets];
    const scopeItem = {
      id: 0,
      category: '',
      codePart1: '',
      codePart2: '',
      description: '',
      unit: '',
      rate: '',
      notes: '',
    };
    if (selectedRow === null) {
      newScopeData[currentSheet].data.push(scopeItem);
    } else {
      newScopeData[currentSheet].data.splice(selectedRow, 0, scopeItem);
    }
    dispatch(setScopeSheets(newScopeData));
  }, [dispatch, selectedRow, currentSheet, scopeSheets]);

  const deleteRow = useCallback(() => {
    const newRemoved = { ...removed };
    const removedItem = scopeSheets[currentSheet].data[selectedRow];
    if (removedItem) {
      if (!newRemoved[currentSheet]) {
        newRemoved[currentSheet] = [];
      }
      if (!newRemoved[currentSheet].includes(removedItem.id)) {
        newRemoved[currentSheet].push(removedItem.id);
      }
    }

    dispatch(setRemoved(newRemoved));

    const newScopeData = [...scopeSheets];
    newScopeData[currentSheet].data.splice(selectedRow, 1);
    dispatch(setScopeSheets(newScopeData));

    dispatch(setHasChanges(true));
  }, [dispatch, selectedRow, scopeSheets, currentSheet, removed]);

  const undo = useCallback(() => {
    dispatch(setHasChanges(false));
    dispatch(setChanges({}));
    dispatch(setRemoved({}));
    dispatch(setScopeSheets(originalScopeSheets.current));
  }, [dispatch]);

  const newCategoryChange = useCallback(
    (e: any) => {
      const newCategory = e.target.value;
      dispatch(setNewCategory(newCategory));
      if (scopeSheets.some((sheet) => sheet.name === newCategory)) {
        dispatch(setNewCategoryFormErrors({ category: ['Category already exists'] }));
      } else {
        dispatch(setNewCategoryFormErrors({ category: [] }));
      }
    },
    [dispatch, scopeSheets]
  );

  const downloadAll = useCallback(
    (e) => {
      e.preventDefault();
      const columns = getColumns();
      const objects = scopeSheets.map((sheet) => sheet.data);
      const sheetNames = scopeSheets.map((sheet) => sheet.name);
      const schemas = Array(objects.length)
        .fill(null)
        .map(() => [...columns]);
      writeXlsxFile(objects, {
        sheets: sheetNames,
        schema: schemas,
        fontFamily: 'Arial',
        fileName: `RocketScope Data ${new Date().toLocaleString()}.xlsx`,
      });
    },
    [scopeSheets]
  );

  const downloadEmpty = useCallback((e) => {
    e.preventDefault();
    const columns = getColumns();
    const sheetNames = ['Category 1', 'Category 2', 'Category 3'];
    const schemas = Array(sheetNames.length)
      .fill(null)
      .map(() => [...columns]);
    const objects = Array(sheetNames.length)
      .fill(null)
      .map(() => []);
    writeXlsxFile(objects, {
      sheets: sheetNames,
      schema: schemas,
      fontFamily: 'Arial',
      fileName: 'RocketScope Template.xslx',
    });
  }, []);

  return (
    <TabContent key="tab-content-rocketscope-view" id="rocketscopeview" className="show active position-relative">
      <div className={classes.sectionContainer}>
        <div className={classes.viewButtonContainer}>
          {/* Enable Editing Button */}
          <PurpleButton
            className={classes.enableEditButton}
            disabled={!scopeSheets || scopeSheets.length === 0}
            onClick={editScope}
          >
            {isEditing ? 'Disable ' : 'Enable '} Editing
          </PurpleButton>
          <PurpleButton
            className={`${classes.iconButton} ${isEditing ? classes.editing : ''}`}
            title={isEditing ? 'Disable Editing' : 'Enable Editing'}
            disabled={!scopeSheets}
            onClick={editScope}
          >
            <Icon type="edit" className={classes.icon} />
          </PurpleButton>
          {/* End Enable Editing Button */}

          {/* Add Row Button */}
          <PurpleButton className={classes.addRowButton} disabled={!isEditing} onClick={addRow}>
            Insert Row
          </PurpleButton>
          <PurpleButton className={classes.iconButton} title="Insert Row" disabled={!isEditing} onClick={addRow}>
            <Icon type="plus" className={classes.icon} />
          </PurpleButton>
          {/* End Row Button */}

          {/* Delete Row Button */}
          <PurpleButton
            className={classes.deleteRowButton}
            disabled={!isEditing || selectedRow === null}
            onClick={deleteRow}
          >
            Delete Row
          </PurpleButton>
          <PurpleButton
            className={classes.iconButton}
            title="Delete Row"
            disabled={!isEditing || selectedRow === null}
            onClick={deleteRow}
          >
            <Icon type="minus" className={classes.icon} />
          </PurpleButton>
          {/* End Delete Row Button */}

          <div className={classes.spacer} />

          <PurpleButton
            className={classes.downloadButton}
            disabled={!scopeSheets || scopeSheets.length === 0}
            onClick={downloadAll}
          >
            Download Excel
          </PurpleButton>
          <PurpleButton
            className={classes.iconButton}
            title="Download as Excel File"
            disabled={!scopeSheets || scopeSheets.length === 0}
            onClick={downloadAll}
          >
            <Icon type="download" className={classes.icon} />
          </PurpleButton>

          <PurpleButton className={classes.downloadButton} onClick={downloadEmpty}>
            Download Empty
          </PurpleButton>
          <PurpleButton className={classes.iconButton} title="Download Empty Excel Template" onClick={downloadEmpty}>
            <Icon type="downloadempty" className={classes.icon} />
          </PurpleButton>

          <div className={classes.spacer} />

          <PurpleButton className={classes.undoButton} onClick={undo} disabled={!hasChanges}>
            Undo
          </PurpleButton>
          <PurpleButton className={classes.iconButton} onClick={undo} disabled={!hasChanges} title="Undo">
            <Icon type="undo" className={classes.icon} />
          </PurpleButton>

          <PurpleButton className={classes.saveButton} onClick={saveChanges} disabled={!hasChanges}>
            Save
          </PurpleButton>
          <PurpleButton className={classes.iconButton} onClick={saveChanges} disabled={!hasChanges} title="Save">
            <Icon type="save" className={classes.icon} />
          </PurpleButton>
        </div>
        <ErrorMessage message={saveError} />
        {scopeSheets && scopeSheets.length > 0 ? (
          <RocketScopeDataGrid
            sheetNames={scopeSheets.map((sheet) => sheet.name)}
            scopeData={scopeSheets.map((sheet) => sheet.data)}
            currentSheet={currentSheet}
            onSelectionChange={onSelectionChange}
            selectedRow={selectedRow}
            onRowsChange={isEditing ? onRowsChange : null}
            setCurrentSheet={(index) => dispatch(setCurrentSheet(index))}
            addSheet={isEditing ? startAddSheetClick : null}
            width={width}
          />
        ) : null}
        <div className={`${classes.spinnerContainer} ${loading ? '' : classes.hidden} `}>
          <SpinnerBlock fetching={loading} />
        </div>
      </div>
      <NewScopeCategoryModal
        isOpen={isNewCategoryOpen}
        formErrors={newCategoryFormErrors}
        modalCloseClick={() => dispatch(setIsNewCategoryOpen(false))}
        onNewCategoryClick={() => newSheetClickHandler.current()}
        newCategory={newCategory}
        onCategoryChange={newCategoryChange}
      />
    </TabContent>
  );
}

const RocketScopeViewTabContainerMemo = memo(RocketScopeViewTabContainer, areEqual);

export { RocketScopeViewTabContainerMemo as RocketScopeViewTabContainer };
